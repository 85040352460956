<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <PageHeader :title="title" class="poppins fw-normal ps-3 pt-3 " />
          <div class="card-body table">
           
            <div class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2">
              <div :class="{
    'del_btn-wrapper': true,
    'border-orange rounded-pill p1': multipuleIds.length >= 1
  }">


<button
  v-if="multipuleIds.length >= 1"
  @click="deleteBulk"
  class="
    btn
    border-0
    px-5
    fw-bold
    text-white
    btn btn-primary
    waves-effect waves-light
    w-md
    primary-button
    rounded-pill
  "
>
  Delete All
</button>
</div>
            <div class="add_new border-orange p1 rounded-pill">  <router-link to="/sub-category/add"
           class="
             btn
             btn-primary
             border-0
             px-5
             fw-bold
             text-white
             btn btn-primary
             waves-effect waves-light
             w-md
             primary-button
             rounded-pill
           "
         >
           Add New Sub Category
            </router-link></div>
         </div>
            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0"
              >
                <thead class="table-light bg-transparent">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          @change="selectAllRecord"
                          v-model="selectAll"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th>Sub Category Title</th>
                    <th>Sub Category Slug</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading" class="text-center">
                    <td colspan="4">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="(subcategory, index) in subCategories"
                    :key="index"
                  >
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          v-model="multipuleIds"
                          :value="subcategory.id"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <td>
                      <span>{{ subcategory.title }}</span>
                    </td>
                    <td>{{ subcategory.slug }}</td>

                    <td class="table-icon">
                      <div class="d-flex">
                        <button
                          style="margin: 10px"
                          @click="subCategoryUpdate(subcategory.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-primary">
                            <i class="uil-edit"></i>
                          </span>
                        </button>
                        <button
                          @click="deleteCategory(subcategory.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "sub-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      multipuleIds: [],
      selectAll: false,
      title: "Sub Category Listing",
      loading: true,
      subCategories: null,
    };
  },
  mounted() {
    this.allSubCategory();
  },
  methods: {
    randerData(response) {
      this.subCategories = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allSubCategory() {
      this.$axios
        .get("sub-categories")
        .then((response) => {
          setInterval(this.randerData(response), 1500);
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("sub-categories/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteCategory(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allSubCategory();
          }
        }
      });
    },
    subCategoryUpdate: function (id) {
      this.$router.push({
        name: "update-sub-category",
        params: { id: id },
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.subCategories.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-subCategory-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allSubCategory();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>